import { Box, Typography } from "@material-ui/core"
import React from "react"
import LayoutProductions from "../../components/layout/layout-productions"

const VirtualStandPage = () => {
  return (
    <LayoutProductions>
      <Box
        pt="100px"
        display="flex"
        width="100vw"
        minHeight="80vh"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h1">PRODUCTIONS/VIRTUAL STAND</Typography>
      </Box>
    </LayoutProductions>
  )
}

export default VirtualStandPage
